import { FiSettings } from 'react-icons/fi';
import { copyToClipboard } from '@/helpers/copyToClipboard';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { useEffect, useState } from 'react';
import Prism from 'prismjs';
import { IoMdCopy } from 'react-icons/io';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import Button from '../Button';

const CodeSnippet = ({ htmlSnippet }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <div className="code">
      <pre>
        <code className="language-html">{htmlSnippet}</code>
      </pre>
    </div>
  );
};

const FormWidgetSettings = ({ form }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const htmlSnippet = `<script> // Burbuja flotante de mantra.chat
  var __m_f = '${form.id}';
  var __m_f_s = document.createElement('script');
  __m_f_s.setAttribute('src', 'https://mantra-forms-script.web.app/embed-widget.js');
  document.body.appendChild(__m_f_s);
</script>`;

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        ariaHideApp={false}
        contentLabel="Configurar burbuja flotante"
        style={{
          content: {
            position: 'absolute',
            border: '0px',
            background: '#F5F7FB',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: '20px',
            margin: '0 auto',
            borderRadius: '0px',
            inset: 0,
          },
        }}
      >
        <div className="text-[#495057]">
          <div
            className="flex justify-start items-center space-x-2 cursor-pointer"
            onClick={() => setIsModalOpen(false)}
          >
            <div>
              <AiOutlineArrowLeft size="25px" />
            </div>
            <span className="text-lg">Regresar</span>
          </div>

          <h1 className="font-bold text-lg md:text-2xl mt-2">Configurar burbuja flotante</h1>

          <p>
            Para insertar la burbuja flotante en tu página web debes copiar el siguiente fragmento
            de código y pegarlo en el código fuente de todas las páginas de tu web donde quieres que
            aparezca.
          </p>

          <p className="my-2">
            El código debe pegarse <b>justo antes del cierre de la etiqueta {`</body>`}</b>.
          </p>

          <CodeSnippet htmlSnippet={htmlSnippet} />

          <Button
            size="sm"
            variant="purple"
            onClick={() => {
              copyToClipboard(htmlSnippet);
              toast.success('Código copiado al portapapeles');
            }}
          >
            <span className="flex items-center justify-center space-x-1">
              <span>Copiar Código</span>
              <span>
                <IoMdCopy size="16px" />
              </span>
            </span>
          </Button>
        </div>
      </Modal>

      <Button
        size="sm"
        variant="purple"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <span className="flex items-center justify-center space-x-1">
          <span>Configurar burbuja flotante</span>
          <span>
            <FiSettings size="16px" />
          </span>
        </span>
      </Button>
    </div>
  );
};

export default FormWidgetSettings;
