import * as yup from 'yup';
import { Formik, Form } from 'formik';
import Button from '@components/Button';
import FmInput from '@components/formik/FmInput';
import axios from 'axios';
import { useState } from 'react';
import { CgSpinner } from 'react-icons/cg';
import { useGetUtm } from '@/hooks/utmHooks';
import getEnvironment from '@/env/getEnvironment';
import FmSelect from '@components/formik/FmSelect';
const env = getEnvironment();

const formSchema = yup.object().shape({
  name: yup.string().required('Tu nombre es obligatorio'),
  phone: yup
    .number()
    .typeError('Solo puedes ingresar números')
    .required('Tu teléfono es obligatorio'),
  help: yup.string().required('Es necesaria la selección'),
});

const FWMason = ({ form, method = 'newTab', source = 'widget-form' }) => {
  const [loading, setLoading] = useState(false);
  const utm = useGetUtm();
  const handleSubmit = async data => {
    setLoading(true);
    try {
      const req = {
        formId: form.id,
        countryCode: data.countryCode,
        phone: data.phone,
        name: data.name,
        surname: '',
        source,
        data: { help: data.help, keja: assistance[data.help]},
        utm,
        ...data,
      };
      console.log({ req });
      const res = (await axios.post(`${env.apiURL}/api/leads`, req)).data;
      console.log({ res });

      const linebreak = `%0A`;

      let message = encodeURIComponent(`Nombre y Apellido: ${data.name}`);
      message += linebreak;
      message += encodeURIComponent(`Su petición: ${assistance[data.help]}`);
      message += linebreak;
      if (res.group?.phone) {
        const url = `https://api.whatsapp.com/send?phone=${res.group?.phone}&text=${message}`;

        if (method === 'newTab') {
          window.open(url, '_blank').focus();
        }
        if (method === 'redirect') {
          window.location.replace(url);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  let assistance = [
    'Consulta de Productos',
    'Asesoría nutricional',
    'Reclamos',
    'Estado de Pedido',
    'Opciones de Compra',
    'Sugerencias y recomendaciones',
  ];
  return (
    <Formik
      initialValues={{
        name: '',
        countryCode: '51',
        correo: '',
        phone: '',
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid }) => (
        <Form className="space-y-2">
          <FmInput
            name="name"
            type="text"
            label="Nombre y apellido"
            placeholder="Escribe tu nombre"
          />
          <FmInput name="phone" type="tel" label="Teléfono" placeholder="Escribe tu teléfono" />
          <FmSelect name="help" label="¿En qué podemos ayudarte?">
            <option value="">Selecciona una opción</option>
            {assistance.map((help_, index) => {
              return (
                <option key={index} value={index}>
                  {help_}
                </option>
              );
            })}
          </FmSelect>

          <Button type="submit" disabled={!isValid || loading}>
            {loading && (
              <div className="flex items-center justify-center">
                <div className="animate-spin">
                  <CgSpinner size="24px" />
                </div>
              </div>
            )}
            {!loading && 'Enviar'}
          </Button>

          <div className="text-center text-gray-500">
            <small>
              &copy; Powered by{' '}
              <a href="https://www.mantra.chat/" target="_blank" rel="noreferrer">
                Mantra.chat
              </a>
            </small>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FWMason;
