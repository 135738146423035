export const formCustomizations = {
  default: {
    background: '#49C858',
    text: '#FFFFFF',
    floatButtonSize: 45,
    floatButtonIconSize: 25,
    paddingFromCorner: 15,
    floatButtonBorderRadius: '50%',
  },
  PVVA6G1XXndbqpHBqXVQ: {
    background: '#29A2AB',
    text: '#FFFFFF',
    floatButtonSize: 50,
    floatButtonIconSize: 28,
    paddingFromCorner: 15,
    floatButtonBorderRadius: '4px',
  },
};

export const getCustomizationFromFormId = formId => {
  if (formCustomizations[formId] != null) return formCustomizations[formId];
  return formCustomizations['default'];
};
