const Textarea = ({ rows = 3, label, id, children, ...rest }) => {
  return (
    <div>
      <label htmlFor={id ? id : label}>{label}</label>
      <textarea
        id={id ? id : label}
        className={`
          w-full text-lg p-2 rounded border border-gray-200 mt-1
          focus:outline-none focus:border-gray-300
        `}
        rows={rows}
        {...rest}
      >
        {children}
      </textarea>
    </div>
  );
};

export default Textarea;
