import { useField } from 'formik';

const FmSelect = ({ label, id, children, ...rest }) => {
  const [field, meta] = useField(rest);

  return (
    <div>
      <label htmlFor={id ? id : label}>{label}</label>
      <select
        id={id ? id : label}
        className={`
          w-full h-[40px] text-lg px-2 rounded border border-gray-200 mt-1
          focus:outline-none focus:border-gray-300
        `}
        {...field}
        {...rest}
      >
        {children}
      </select>
      {meta.touched && meta.error && <span className="text-red-500">{meta.error}</span>}
    </div>
  );
};

export default FmSelect;
