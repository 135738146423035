import { useEffect } from 'react';
import * as qs from 'query-string';

const sessionStorageKey = '__utmParametersHook__';

export const useParseUtm = () => {
  useEffect(() => {
    // Parse Query Parameters for UTM values
    const query = qs.parse(window.location.search);

    // Save utm in session storage for this unique session
    const utm = {
      utm_source: query.utm_source || null,
      utm_medium: query.utm_medium || null,
      utm_campaign: query.utm_campaign || null,
      utm_term: query.utm_term || null,
      utm_content: query.utm_content || null,
    };

    if (utm.utm_source || utm.utm_medium || utm.utm_campaign || utm.utm_term || utm.utm_content) {
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(utm));
    }
  }, []);
};

export const useGetUtm = () => {
  const utm = JSON.parse(sessionStorage.getItem(sessionStorageKey)) || {};

  const formattedUtm = {
    utm_source: utm.utm_source ?? null,
    utm_medium: utm.utm_medium ?? null,
    utm_campaign: utm.utm_campaign ?? null,
    utm_term: utm.utm_term ?? null,
    utm_content: utm.utm_content ?? null,
  };

  return formattedUtm;
};
