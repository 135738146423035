const Button = ({ children, variant = 'default', size = 'md', ...rest }) => {
  return (
    <button
      className={`
        w-full rounded
        disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed

        ${size === 'sm' ? 'p-1 min-h-[20px]' : ''}
        ${size === 'md' ? 'p-2 min-h-[40px]' : ''}

        ${variant === 'default' ? 'bg-[#49C858] text-white' : ''}
        ${variant === 'purple' ? 'bg-[#7269ef] hover:bg-[#5246eb] text-white' : ''}
      `}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
