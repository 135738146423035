import FWDefault from './templates/FWDefault';
import FWEvolta from './templates/FWEvolta';
import FWColegioSkinner from './templates/FWColegioSkinner';
import FWCentroSkinner from './templates/FWCentroSkinner';
import FWProyectosInventInmobiliaria from './templates/FWProyectosInventInmobiliaria';
import FWJJCComunicaciones from './templates/FWJJCComunicaciones';
import FWVyvForm1 from './templates/FWVyvForm1';
import FWVyvForm2 from './templates/FWVyvForm2';
import FWMantraForm1 from './templates/FWMantraForm1';
import FWMantraForm2 from './templates/FWMantraForm2';
import FWMantraForm3 from './templates/FWMantraForm3';
import FWMilepa from './templates/FWMilepa';
import FWVidarqForm1 from './templates/FWVidarqForm1';
import FWCantabriaForm1 from './templates/FWCantabriaForm1';
import FWSiseExtensionProfesional from './templates/FWSiseExtensionProfesional';
import FWSiseCarreras from './templates/FWSiseCarreras';
import FWSiseFormacionEjecutiva from './templates/FWSiseFormacionEjecutiva';
import FWSiseIngles from './templates/FWSiseIngles';
import FWAImpacto from './templates/FWAImpacto';
import DesImb01 from './templates/DesImb01';
import FWPraderasDelNorte from './templates/FWPraderasDelNorte';
import FWMason from './templates/FWMason';
import FWDelNorte from './templates/FWDelNorte';
import FWDelNortePrado from './templates/FWDelNortePrado';
import FWVillaDelPrado from './templates/FWVillaDelPrado';
import FWMaasWeb from './templates/FWMaasWeb';
import FWMaasZona from './templates/FWMaasZona';
import FWMaasLinktree from './templates/FWMaasLinktree';
import FWMaasMailing from './templates/FWMaasMailing';
import FWMantraForm4 from './templates/FWMantraForm4';
import FWOrtizInmobiliaria from './templates/FWOrtizInmobiliaria';
import FWVivaldi from './templates/FWVivaldi';

const FormWidget = props => {
  switch (props.form.template) {
    case 'evolta':
      return <FWEvolta {...props} />;
    case 'colegio-skinner':
      return <FWColegioSkinner {...props} />;
    case 'centro-skinner':
      return <FWCentroSkinner {...props} />;
    case 'proyectos-invent-inmobiliaria':
      return <FWProyectosInventInmobiliaria {...props} />;
    case 'jjc-comunicaciones':
      return <FWJJCComunicaciones {...props} />;
    case 'vyv-form-1':
      return <FWVyvForm1 {...props} />;
    case 'vyv-form-2':
      return <FWVyvForm2 {...props} />;
    case 'mantra-form-1':
      return <FWMantraForm1 {...props} />;
    case 'mantra-form-2':
      return <FWMantraForm2 {...props} />;
    case 'mantra-form-3':
      return <FWMantraForm3 {...props} />;
    case 'mantra-form-4':
      return <FWMantraForm4 {...props} />;
    case 'milepa-form-1':
      return <FWMilepa {...props} />;
    case 'vidarq-form-1':
      return <FWVidarqForm1 {...props} />;
    case 'cantabria-form-1':
      return <FWCantabriaForm1 {...props} />;
    case 'sise-form-carreras':
      return <FWSiseCarreras {...props} />;
    case 'sise-form-formacion-ejecutiva':
      return <FWSiseFormacionEjecutiva {...props} />;
    case 'sise-form-extension-profesional':
      return <FWSiseExtensionProfesional {...props} />;
    case 'sise-form-ingles':
      return <FWSiseIngles {...props} />;
    case 'aimpacto-form-01':
      return <FWAImpacto {...props} />;
    case 'desarrolladora-form-01':
      return <DesImb01 {...props} />;
    case 'praderas-del-norte': //
      return <FWPraderasDelNorte {...props} />;
    case 'form-mason':
      return <FWMason {...props} />;
    case 'form-del-norte':
      return <FWDelNorte {...props} />;
    case 'form-del-norte-prado':
      return <FWDelNortePrado {...props} />;
    case 'form-villa-del-prado':
      return <FWVillaDelPrado {...props} />;
    case 'form-maas-web':
      return <FWMaasWeb {...props} />;
    case 'form-maas-zona':
      return <FWMaasZona {...props} />;
    case 'form-maas-linktree':
      return <FWMaasLinktree {...props} />;
    case 'form-maas-mailing':
      return <FWMaasMailing {...props} />;
    case 'form-ortiz-inmobiliaria':
      return <FWOrtizInmobiliaria {...props} />;
    case 'form-vivaldi-inmobiliaria':
      return <FWVivaldi {...props} />;
    default:
      return <FWDefault {...props} />;
  }
};

export default FormWidget;
