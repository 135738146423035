import * as qs from 'query-string';
import { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { MdClose } from 'react-icons/md';
import FormWidget from '@/components/form/FormWidget';
import { useGetForm } from '@/hooks/useGetForm';
import { useParams } from 'react-router-dom';
import { getCustomizationFromFormId } from '@/data/formCustomizations';

const FormFloatingButtonWidget = () => {
  let query = {};
  if (typeof window !== 'undefined') {
    query = qs.parse(window.location.search);
  }

  // Obtener formulario
  const { formId } = useParams();
  const { form, loading: getFormLoading } = useGetForm(formId);
  // Obtener formulario

  // Definicion del tamaño y estilo de los elementos
  const formCustomization = getCustomizationFromFormId(formId);
  const maxWidgetWidth = query.mw ? query.mw : 0;
  const maxWidgetHeight = query.mh ? query.mh : 0;
  const floatButtonSize = formCustomization.floatButtonSize;
  const floatButtonIconSize = formCustomization.floatButtonIconSize;
  const floatButtonBorderRadius = formCustomization.floatButtonBorderRadius;
  const paddingFromCorner = formCustomization.paddingFromCorner;
  //

  // Estado y tamaño (reactivo) del widget
  const { width, height, ref: wrapperRef } = useResizeDetector();
  const isMobileVersion = width < maxWidgetWidth;
  const [isFormOpen, setIsFormOpen] = useState(false);
  //

  // Cada vez que cambie el estado o el tamaño del widget se lo avisamos al padre
  useEffect(() => {
    if (typeof window !== 'undefined' && width && height) {
      let postMessage = {
        channel: 'ChangeMantraChatWidgetIframeStatus',
        width,
        height,
        isMobileVersion,
        isFormOpen,
      };

      window.parent.postMessage(JSON.stringify(postMessage), '*');
    }
  }, [width, height, isFormOpen, isMobileVersion]);
  //

  // Inicializamos los tamaños del html, body y div principal y se lo comunicamos al padre
  useEffect(() => {
    document.querySelector('html').style.height = '100%';
    document.body.style.height = '100%';
    document.querySelector('#root').style.height = '100%';

    let postMessage = {
      channel: 'ChangeMantraChatWidgetIframeStatus',
      width: floatButtonSize + paddingFromCorner * 2,
      height: floatButtonSize + paddingFromCorner * 2,
      isMobileVersion: false,
      isFormOpen: false,
    };

    window.parent.postMessage(JSON.stringify(postMessage), '*');
  }, [floatButtonSize, paddingFromCorner]);
  //

  if (getFormLoading || !form) {
    return <></>;
  }

  return (
    <div className={`fixed w-full bottom-0 h-full`}>
      <div
        ref={wrapperRef}
        style={{
          maxWidth: isFormOpen ? '100%' : floatButtonSize + paddingFromCorner * 2,
          height: isFormOpen ? (isMobileVersion ? '100%' : `${maxWidgetHeight}px`) : '',
        }}
      >
        <div
          style={{ padding: !isMobileVersion || !isFormOpen ? `${paddingFromCorner}px` : '' }}
          className="h-full"
        >
          {/* Chat Button */}
          <div
            className={`
              flex cursor-pointer ${isFormOpen ? 'hidden' : ''}
              animate__animated animate__fadeIn animate__faster
            `}
          >
            <div
              className={`
                flex items-center justify-center
              `}
              jjj={`${floatButtonSize}px`}
              style={{
                backgroundColor: formCustomization.background,
                width: `${floatButtonSize}px`,
                height: `${floatButtonSize}px`,
                borderRadius: floatButtonBorderRadius,
              }}
              onClick={() => setIsFormOpen(true)}
            >
              <img
                src="/assets/images/whatsapp-symbol.svg"
                alt="Logo de WhatsApp"
                width={`${floatButtonIconSize}px`}
                height={`${floatButtonIconSize}px`}
              />
            </div>
          </div>
          {/* Chat Button */}

          {/* Chat Form */}
          <div
            className={`
              ${isFormOpen ? 'w-full h-full max-h-full shadow-md' : 'hidden'}
              animate__animated animate__fadeInUp animate__faster
            `}
          >
            <div className="w-full h-full max-h-full">
              <div
                className={`
                  flex justify-between items-center bg-[#49C858] text-white h-[60px] px-[1rem]
                  ${isMobileVersion ? '' : 'rounded-t'}
                `}
              >
                <h1 className="text-xl">Chatea con nosotros</h1>
                <div onClick={() => setIsFormOpen(false)} className="cursor-pointer">
                  <MdClose size="25px" color="#ffffff" />
                </div>
              </div>
              <div
                className={`
                  p-[1rem] bg-white h-[calc(100%-60px)] overflow-auto
                  ${isMobileVersion ? '' : 'rounded-b'}
                `}
              >
                <FormWidget form={form} source="widget-form" />
              </div>
            </div>
          </div>
          {/* Chat Form */}
        </div>
      </div>
    </div>
  );
};

export default FormFloatingButtonWidget;
