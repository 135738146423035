import { productionEnvironment } from './production';
import { stagingEnvironment } from './staging';

const getEnvironment = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return productionEnvironment;
  } else {
    return stagingEnvironment;
  }
};

export default getEnvironment;
