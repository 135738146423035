const Input = ({ label, id, ...rest }) => {
  return (
    <div>
      <label htmlFor={id ? id : label}>{label}</label>
      <input
        id={id ? id : label}
        className={`
          w-full h-[40px] text-lg px-2 rounded border border-gray-200 mt-1
          focus:outline-none focus:border-gray-300
        `}
        {...rest}
      />
    </div>
  );
};

export default Input;
