export const productionEnvironment = {
  firebaseConfig: {
    apiKey: 'AIzaSyAH_x5mmL-Ge6PmYBqDBna6vIKktAA9i9s',
    authDomain: 'mantra-forms.firebaseapp.com',
    projectId: 'mantra-forms',
    storageBucket: 'mantra-forms.appspot.com',
    messagingSenderId: '572151068560',
    appId: '1:572151068560:web:8e9c6c0233c626db22e515',
  },
  apiURL: 'https://forms.mantra.chat',
};
