import Button from '@components/Button';
import Input from '@components/Input';
import Textarea from '@components/Textarea';
import { useGetUtm } from '@/hooks/utmHooks';

const FWJJCComunicaciones = ({ form, method = 'newTab', source = 'widget-form' }) => {
  const utm = useGetUtm();

  return (
    <form
      className="space-y-2"
      action="https://getform.io/f/fc5c8368-777e-4ac8-a602-23d08c2c1e06"
      method="POST"
      enctype="multipart/form-data"
    >
      <input type="hidden" id="phone" name="phone" value={utm.utm_campaign ?? ''} />

      <Input
        name="name"
        type="text"
        label="Nombre y apellido"
        placeholder="Escribe tu nombre"
        required
      />

      <Input
        name="dni"
        type="text"
        label="DNI"
        placeholder="Escribe tu dni"
        required
        maxlength="8"
        minlength="8"
      />

      <Input
        name="project"
        type="text"
        label="Proyecto"
        placeholder="Nombre del proyecto"
        required
      />

      <Textarea
        name="message"
        type="text"
        label="Escribe lo que significa para ti la navidad"
        placeholder="Escribe aquí..."
        required
      />

      <div>
        <label htmlFor="file">Adjunta tu foto o video</label>
        <input
          id="file"
          className={`
            w-full py-2 text-lg px-2 rounded border border-gray-200 mt-1
            focus:outline-none focus:border-gray-300
          `}
          type="file"
          name="file"
          accept="image/*, video/*"
          required
        />
      </div>

      <Button type="submit">Enviar mis datos</Button>

      <div className="text-center text-gray-500">
        <small>
          &copy; Powered by{' '}
          <a href="https://www.mantra.chat/" target="_blank" rel="noreferrer">
            Mantra.chat
          </a>
        </small>
      </div>
    </form>
  );
};

export default FWJJCComunicaciones;
