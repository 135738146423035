import { useState, useEffect } from 'react';
import { db } from '@lib/firebase';
import { doc, getDoc } from 'firebase/firestore';

export const useGetForm = formId => {
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const snap = await getDoc(doc(db, 'forms', formId));
        if (snap.exists()) {
          setForm({ ...snap.data(), id: formId });
        } else {
          console.log('Formulario no encontrado');
          setForm(null);
        }
      } catch (error) {
        console.log(error);
        setForm(null);
      } finally {
        setLoading(false);
      }
    })();
  }, [formId]);

  return { loading, form };
};
