import * as yup from 'yup';
import { Formik, Form } from 'formik';
import Button from '@components/Button';
import FmInput from '@components/formik/FmInput';
import FmSelect from '@components/formik/FmSelect';
import axios from 'axios';
import { useState } from 'react';
import { CgSpinner } from 'react-icons/cg';
import { useGetUtm } from '@/hooks/utmHooks';
import getEnvironment from '@/env/getEnvironment';

const env = getEnvironment();

const formSchema = yup.object().shape({
  name: yup.string().required('Tu nombre es obligatorio'),
  phone: yup.string().required('Tu teléfono es obligatorio'),
  email: yup.string().email('Correo electrónico no válido').required('Campo obligatorio'),
  degree: yup.string().min(1, 'Campo obligatorio').required('Campo obligatorio'),
});

const FWColegioSkinner = ({ form, method = 'newTab', source = 'widget-form' }) => {
  const [loading, setLoading] = useState(false);
  const utm = useGetUtm();

  const handleSubmit = async data => {
    setLoading(true);
    try {
      const req = {
        formId: form.id,
        countryCode: form.defaultCountryCode.replace(/\+/g, ''),
        phone: data.phone,
        name: data.name,
        surname: data.lastname,
        source,
        data: {
          email: data.email,
          degree: data.degree,
        },
        utm,
        ...data,
      };
      console.log({ req });
      const res = (await axios.post(`${env.apiURL}/api/leads`, req)).data;
      console.log({ res });

      const linebreak = `%0A`;

      let message = encodeURIComponent(`Nombres: ${data.name}`);
      message += linebreak;
      message += encodeURIComponent(`Correo electrónico: ${data.email}`);
      message += linebreak;
      message += encodeURIComponent(`Grado al que postula: ${data.degree}`);
      message += linebreak;

      if (res.group?.phone) {
        const url = `https://api.whatsapp.com/send?phone=${res.group?.phone}&text=${message}`;

        if (method === 'newTab') {
          window.open(url, '_blank').focus();
        }
        if (method === 'redirect') {
          window.location.replace(url);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        name: '',
        lastname: '',
        phone: '',
        email: '',
        degree: '',
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid }) => (
        <Form className="space-y-2">
          <FmInput
            name="name"
            type="text"
            label="Nombres"
            placeholder="Escribe tu nombre"
          />
          <FmInput
            name="lastname"
            type="text"
            label="Apellidos"
            placeholder="Escribe tu apellido"
          />

          <FmInput name="phone" type="tel" label="Teléfono" placeholder="Escribe tu teléfono" />

          <FmInput
            name="email"
            type="email"
            label="Correo electrónico"
            placeholder="Escribe tu correo electrónico"
          />

          <FmSelect name="degree" label="Grado al que postula">
            <option value="">Selecciona una opción</option>
            <option value="Pre-Kinder">Pre-Kinder</option>
            <option value="Kinder">Kinder</option>
            <option value="1ero de primaria">1ero Primaria</option>
            <option value="2do de primaria">2do de Primaria</option>
            <option value="3ero de primaria">3ero de primaria</option>
            <option value="4to de primaria">4to de primaria</option>
            <option value="5to de primaria">5to de primaria</option>
            <option value="6to de primaria">6to de primaria</option>
            <option value="1ero de secundaria">1ero de secundaria</option>
            <option value="2do de secundaria">2do de secundaria</option>
            <option value="3ro de secundaria">3ro de secundaria</option>
            <option value="4to de secundaria">4to de secundaria</option>
            <option value="5to de secundaria">5to de secundaria</option>
          </FmSelect>

          <Button type="submit" disabled={!isValid || loading}>
            {loading && (
              <div className="flex items-center justify-center">
                <div className="animate-spin">
                  <CgSpinner size="24px" />
                </div>
              </div>
            )}
            {!loading && 'Enviar'}
          </Button>

          <div className="text-center text-gray-500">
            <small>
              &copy; Powered by{' '}
              <a href="https://www.mantra.chat/" target="_blank" rel="noreferrer">
                Mantra.chat
              </a>
            </small>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FWColegioSkinner;
