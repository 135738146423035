// import PageForm from '@/components/form/PageForm';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useGetForm } from '@/hooks/useGetForm';
import { useParams } from 'react-router-dom';
import FormWidget from '@/components/form/FormWidget';

const FormIndex = () => {
  // Obtener formulario
  const { formId } = useParams();
  const { form, loading: getFormLoading } = useGetForm(formId);
  // Obtener formulario

  // Cargando
  if (getFormLoading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }
  // Cargando

  // Si no existe el formulario
  if (!form) {
    return <></>;
  }
  // Si no existe el formulario

  return (
    <div className="md:pb-[20px] min-h-screen" id="chat">
      <style jsx="true">
        {`
          #chat {
            position: relative;
          }

          #chat::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: url(/assets/images/background-texture.png);
            opacity: 0.08;
          }

          #chat::after {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -2;
            background: #e5ddd5;
          }
        `}
      </style>

      {/* Franja verde */}
      <div className="bg-[#00BFA5] w-full h-[222px] absolute left-0 top-0 z-0"></div>
      {/* Franja verde */}

      {/* Cabecera */}
      <div className="text-white relative z-10">
        <div className="max-w-screen-md mx-auto py-8 px-4 md:px-0">
          <div className="flex items-center space-x-2">
            <img
              src="/assets/images/whatsapp-symbol.svg"
              alt="Logo de WhatsApp"
              className="w-[40px] h-[40px]"
            />
            <span className="font-semibold">FORMULARIO DE WHATSAPP</span>
          </div>
        </div>
      </div>
      {/* Cabecera */}

      {/* Formulario */}
      <div className="bg-white max-w-screen-md mx-auto p-4 relative z-10 space-y-6 min-h-screen md:min-h-0 md:rounded md:shadow-md">
        {/* TODO: Hacer titulo dinámico */}
        {form.template === 'jjc-comunicaciones' && (
          <h1 className="text-2xl font-thin">Ingresa tus datos para participar en el sorteo</h1>
        )}
        {form.template !== 'jjc-comunicaciones' && (
          <h1 className="text-2xl font-thin">Ingresa tus datos para continuar en WhatsApp</h1>
        )}

        <FormWidget form={form} method="redirect" source="web-form" />
      </div>
      {/* Formulario */}
    </div>
  );
};

export default FormIndex;
