import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import 'prismjs/themes/prism-tomorrow.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'animate.css';
import '@/styles/globals.css';

import { useParseUtm } from './hooks/utmHooks';
import NotFound from '@pages/NotFound';
import FormIndex from '@pages/[formId]/FormIndex';
import FormFloatingButtonWidget from '@pages/[formId]/FormFloatingButtonWidget';
import ManageForms from './pages/ManageForms';

const App = () => {
  useParseUtm();

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/manage-forms">
            <ManageForms />
          </Route>
          <Route exact path="/:formId/floating-button-widget">
            <FormFloatingButtonWidget />
          </Route>
          <Route exact path="/:formId">
            <FormIndex />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>

      <ToastContainer position="bottom-right" />
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
