const testingGroup = '618ea5086f26bf0018b5c31e'; // Aryadev default

export const availableFormTemplates = [
  {
    id: 'default',
    name: 'Plantilla por defecto',
  },
  {
    id: 'evolta',
    name: 'Evolta',
    groups: [
      '6176b8b168bcf00011f8f55e', // Production VyV
    ],
  },
  {
    id: 'colegio-skinner',
    name: 'Colegio Skinner',
    groups: [
      '619d2c0d1507b60011b9b489', // Colegio Skinner
    ],
  },
  {
    id: 'centro-skinner',
    name: 'Centro Skinner',
    groups: [
      '611e6d113a20770011785cca', // Centro Skinner
    ],
  },
  {
    id: 'proyectos-invent-inmobiliaria',
    name: 'Proyectos Invent Inmobiliaria',
    groups: [
      '615f6a051c36b100112acf78', // Invent Inmobiliaria
    ],
  },
  {
    id: 'jjc-comunicaciones',
    name: 'JJC Comunicaciones',
    groups: [
      '61683cb3715e7a0018a1680b', // Staging Arya Forms
      '618554d805b0420011d35a10', // JJC Comunicaciones
    ],
  },
  {
    id: 'vyv-form-1',
    name: 'VyV Form 1',
    groups: [
      '6176b8b168bcf00011f8f55e', // VyV Grupo Inmobiliario
    ],
  },
  {
    id: 'vyv-form-2',
    name: 'VyV Form 2',
    groups: [
      '6176b8b168bcf00011f8f55e', // VyV Grupo Inmobiliario
    ],
  },
  {
    id: 'mantra-form-1',
    name: 'Mantra Form 1',
    groups: [
      '6130f34fcf3d5000111b5e8a', // Mantra
    ],
  },
  {
    id: 'mantra-form-2',
    name: 'Mantra Form 2',
    groups: [
      '6130f34fcf3d5000111b5e8a', // Mantra
    ],
  },
  {
    id: 'mantra-form-3',
    name: 'Mantra Form 3',
    groups: [
      '6130f34fcf3d5000111b5e8a', // Mantra
    ],
  },
  {
    id: 'mantra-form-4',
    name: 'Mantra Form 4',
    groups: [
      '6130f34fcf3d5000111b5e8a', // Mantra
    ],
  },
  {
    id: 'milepa-form-1',
    name: 'Milepa form 1',
    groups: [
      '61faa69a50197f0011d5be34', // Mantra
    ],
  },
  {
    id: 'vidarq-form-1',
    name: 'Vidarq form 1',
    groups: ['6233c2eb99ce61001161686d'],
  },
  {
    id: 'cantabria-form-1',
    name: 'Cantabria form 1',
    groups: ['620c945ae647d30011df14fb'],
  },
  {
    id: 'sise-form-carreras',
    name: 'Sise Form Carreras',
    groups: ['615f69d61c36b100112acf77'],
  },
  {
    id: 'sise-form-formacion-ejecutiva',
    name: 'Sise Form Formación Ejecutiva',
    groups: ['615f69d61c36b100112acf77'],
  },
  {
    id: 'sise-form-extension-profesional',
    name: 'Sise Form Extensión Profesional',
    groups: ['615f69d61c36b100112acf77'],
  },
  {
    id: 'sise-form-ingles',
    name: 'Sise Form Inglés',
    groups: ['615f69d61c36b100112acf77'],
  },
  {
    id: 'aimpacto-form-01',
    name: 'Alto Impacto Form 01',
    groups: ['627537e65fc33a00117090a9'],
  },
  {
    id: 'desarrolladora-form-01',
    name: 'Desarrolladora Form 01',
    groups: ['6272bb381e82f10011ff9d26'],
  },
  {
    id: 'praderas-del-norte',
    name: 'Praderas del Norte',
    groups: ['6294fb2d9c3b72001184a079'],
  },
  {
    id: 'form-mason',
    name: 'Mason',
    groups: ['62852e555b5f8500110a8b7b'],
  },
  {
    id: 'form-del-norte',
    name: 'Del Norte',
    groups: ['62cf2c778d29ea0010dc1456'],
  },
  {
    id: 'form-del-norte-prado',
    name: 'Del Norte Villa del Prado',
    groups: ['62cf2c778d29ea0010dc1456'],
  },
  {
    id: 'form-villa-del-prado',
    name: 'Villa del Prado',
    groups: ['63038f2b75c90400110eb7b7'],
  },
  {
    id: 'form-maas-web',
    name: 'Maas Web',
    groups: ['63191b561afb780011e39e84'],
  },
  {
    id: 'form-maas-zona',
    name: 'Maas Zona',
    groups: ['63191b561afb780011e39e84'],
  },
  {
    id: 'form-maas-linktree',
    name: 'Maas Linktree',
    groups: ['63191b561afb780011e39e84'],
  },
  {
    id: 'form-maas-mailing',
    name: 'Maas Mailing',
    groups: ['63191b561afb780011e39e84'],
  },
  {
    id: 'form-ortiz-inmobiliaria',
    name: 'Ortiz Inmobiliaria',
    groups: ['6349c4ff05fe7600116394d2'],
  },
  {
    id: 'form-vivaldi-inmobiliaria',
    name: 'Vivaldi Inmobiliaria',
    groups: ['63bc33844c7de600110f0a96'],
  },
];

export const getAvailableFormTemplatesByGroupId = groupId => {
  return availableFormTemplates.filter(availableFormTemplate => {
    if (!!availableFormTemplate.groups === false) {
      return true;
    }

    if (groupId === testingGroup) {
      return true;
    }

    return availableFormTemplate.groups.includes(groupId);
  });
};
