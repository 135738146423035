import * as yup from 'yup';
import { Formik, Form } from 'formik';
import Button from '@components/Button';
import FmInput from '@components/formik/FmInput';
import FmSelect from '@components/formik/FmSelect';
import { countryCodes, getCountryByDialCode } from '@/data/countryCodes';
import axios from 'axios';
import { useState } from 'react';
import { CgSpinner } from 'react-icons/cg';
import { useGetUtm } from '@/hooks/utmHooks';
import getEnvironment from '@/env/getEnvironment';

const env = getEnvironment();

const formSchema = yup.object().shape({
  name: yup.string().required('Tu nombre es obligatorio'),
  countryCode: yup
    .string()
    .required('Código de país obligatorio')
    .min(1, 'Código de país obligatorio'),
  phone: yup.string().required('Tu teléfono es obligatorio'),
  email: yup.string().email('Correo electrónico no válido').required('Campo obligatorio'),
  campus: yup.string().required('Campo obligatorio'),
  service: yup.string().required('Campo obligatorio'),
});

const FWSiseIngles = ({ form, method = 'newTab', source = 'widget-form' }) => {
  const [loading, setLoading] = useState(false);
  const utm = useGetUtm();

  const handleSubmit = async data => {
    setLoading(true);
    try {
      const req = {
        formId: form.id,
        countryCode: data.countryCode,
        phone: data.phone,
        name: data.name,
        surname: data.surname,
        source,
        data: {
          email: data.email,
          campus: data.campus,
          service: data.service,
          ocupacion: data.ocupacion,
        },
        utm,
        ...data,
      };
      console.log({ req });
      const res = (await axios.post(`${env.apiURL}/api/leads`, req)).data;
      console.log({ res });

      const linebreak = `%0A`;

      let message = encodeURIComponent(`Nombre y apellido: ${data.name}`);
      message += linebreak;
      message += encodeURIComponent(`Correo electrónico: ${data.email}`);
      message += linebreak;
      message += encodeURIComponent(`Programa: ${data.service}`);
      message += linebreak;
      message += encodeURIComponent(`Sede: ${data.campus}`);
      message += linebreak;

      if (res.group?.phone) {
        const url = `https://api.whatsapp.com/send?phone=${res.group?.phone}&text=${message}`;

        if (method === 'newTab') {
          window.open(url, '_blank').focus();
        }
        if (method === 'redirect') {
          window.location.replace(url);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        name: '',
        countryCode: form.defaultCountryCode,
        phone: '',
        campus: '',
        service: '',
        ocupacion: '',
        surname: '',
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid }) => (
        <Form className="space-y-2">
          <FmInput
            name="name"
            type="text"
            label="Nombre y apellido"
            placeholder="Escribe tu nombre y apellido"
          />

          <FmSelect name="service" label="Programa">
            <option value="">Selecciona una opción</option>
            <option value={`Inglés Regular Diario`}>{`Inglés Regular Diario`}</option>
            <option value={`Inglés Regular Sabatino`}>{`Inglés Regular Sabatino`}</option>
            <option value={`Inglés Kids & Junior`}>{`Inglés Kids & Junior`}</option>
            <option value={`Methodology`}>{`Methodology`}</option>
            <option value={`English Conversation Course`}>{`English Conversation Course`}</option>
          </FmSelect>

          <FmSelect name="campus" label="Sede">
            <option value="">Selecciona una sede</option>
            <option value="Comas">Comas</option>
            <option value="Puente Piedra">Puente Piedra</option>
            <option value="San Miguel">San Miguel</option>
          </FmSelect>

          <FmSelect name="countryCode" label="Código de país">
            {form.defaultCountryCode && getCountryByDialCode(form.defaultCountryCode) ? (
              <option value={`${form.defaultCountryCode}`}>
                {`${getCountryByDialCode(form.defaultCountryCode).name} (${
                  getCountryByDialCode(form.defaultCountryCode).dial_code
                })`}
              </option>
            ) : (
              <option value="">Selecciona una opción</option>
            )}

            {countryCodes.map((countryCode, index) => {
              return (
                <option key={index} value={`${countryCode.dial_code.replace(/\+/g, '')}`}>
                  {`${countryCode.name} (${countryCode.dial_code})`}
                </option>
              );
            })}
          </FmSelect>

          <FmInput name="phone" type="tel" label="Teléfono" placeholder="Escribe tu teléfono" />

          <FmInput
            name="email"
            type="email"
            label="Correo electrónico"
            placeholder="Escribe tu correo electrónico"
          />

          <Button type="submit" disabled={!isValid || loading}>
            {loading && (
              <div className="flex items-center justify-center">
                <div className="animate-spin">
                  <CgSpinner size="24px" />
                </div>
              </div>
            )}
            {!loading && 'Enviar'}
          </Button>

          <div className="text-center text-gray-500">
            <small>
              &copy; Powered by{' '}
              <a href="https://www.mantra.chat/" target="_blank" rel="noreferrer">
                Mantra.chat
              </a>
            </small>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FWSiseIngles;
