const LoadingSpinner = () => {
  return (
    <div
      className="border-[4px] rounded-full border-[#00BFA5] w-[50px] h-[50px] animate-spin"
      style={{ borderBottomColor: 'transparent' }}
    ></div>
  );
};

export default LoadingSpinner;
