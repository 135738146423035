import dayjs from 'dayjs';
import { CgTrash, CgSpinner } from 'react-icons/cg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { CgExternal } from 'react-icons/cg';
import { IoMdCopy } from 'react-icons/io';
import { copyToClipboard } from '@/helpers/copyToClipboard';
import FormWidgetSettings from './FormWidgetSettings';
import Button from '../Button';
import getEnvironment from '@/env/getEnvironment';

const env = getEnvironment();

const ListForms = ({ token, groupId, onReload, forms }) => {
  const [loading, setLoading] = useState(false);
  const currentUrl = document.location.origin;

  const deleteForm = async id => {
    if (loading) return;

    if (window.confirm('¿Deseas borrar este formulario?')) {
      setLoading(true);

      try {
        await axios.delete(`${env.apiURL}/api/forms/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            GroupId: groupId,
          },
        });

        toast.success('Formulario borrado con éxito');
      } catch (error) {
        toast.error(error.message || 'Ocurrió un error inesperado');
        console.log(error);
      } finally {
        setLoading(false);
        onReload && onReload();
      }
    }
  };

  return (
    <div
      className={`
        grid grid-cols-1 gap-2
      `}
    >
      {forms?.map((form, index) => (
        <div key={index} className="bg-white p-2 rounded border">
          <div className="flex justify-between">
            <h2 className="font-bold text-lg break-all">{form.name}</h2>
            <div className="cursor-pointer" onClick={() => deleteForm(form.id)}>
              {loading && (
                <CgSpinner size="25px" color="#888" className={loading ? 'animate-spin' : ''} />
              )}
              {!loading && <CgTrash size="25px" color="#f00" />}
            </div>
          </div>
          <ul>
            <li>
              <b>Fecha de creación: </b>
              {dayjs(form.createdAt).format('DD/MM/YYYY HH:mm')}
            </li>
            <li>
              <b>Código de país por defecto: </b>
              {`+${form.defaultCountryCode}`}
            </li>
          </ul>

          <hr className="my-2" />

          <div className="space-y-2">
            <span className="block">Link del formulario:</span>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <a href={`${currentUrl}/${form.id}`} target="_blank" rel="noreferrer">
                  <Button size="sm" variant="purple">
                    <span className="flex items-center justify-center space-x-1">
                      <span>Abrir</span>
                      <span>
                        <CgExternal size="18px" />
                      </span>
                    </span>
                  </Button>
                </a>
              </div>
              <div>
                <Button
                  size="sm"
                  variant="purple"
                  onClick={() => {
                    toast.success('Link copiado al portapapeles');
                    copyToClipboard(`${currentUrl}/${form.id}`);
                  }}
                >
                  <span className="flex items-center justify-center space-x-1">
                    <span>Copiar</span>
                    <span>
                      <IoMdCopy size="16px" />
                    </span>
                  </span>
                </Button>
              </div>
            </div>
          </div>

          <hr className="my-2" />

          <FormWidgetSettings form={form} />
        </div>
      ))}
    </div>
  );
};

export default ListForms;
