import * as yup from 'yup';
import { Formik, Form } from 'formik';
import Button from '@components/Button';
import FmInput from '@components/formik/FmInput';
import axios from 'axios';
import { useState } from 'react';
import { CgSpinner } from 'react-icons/cg';
import { useGetUtm } from '@/hooks/utmHooks';
import getEnvironment from '@/env/getEnvironment';
import FmSelect from '@components/formik/FmSelect';
import { countryCodes, getCountryByDialCode } from '@/data/countryCodes';
const env = getEnvironment();

const formSchema = yup.object().shape({
  name: yup.string().required('Tu nombre es obligatorio'),
  phone: yup
    .number()
    .typeError('Solo puedes ingresar números')
    .required('Tu teléfono es obligatorio'),
  email: yup
    .string()
    .email('Correo electrónico no válido')
    .required('Es necesario tu correo electrónico'),
  proyecto: yup.string().required('Es necesaria la selección'),
});

const FWOrtizInmobiliaria = ({ form, method = 'newTab', source = 'widget-form' }) => {
  const [loading, setLoading] = useState(false);
  const utm = useGetUtm();
  const handleSubmit = async data => {
    setLoading(true);
    try {
      const req = {
        formId: form.id,
        countryCode: data.countryCode,
        phone: data.phone,
        name: data.name,
        surname: '',
        source,
        data: {
          proyecto: data.proyecto,
          id: '530',
          email: data.email,
        },
        utm,
        ...data,
      };
      console.log({ req });
      const res = (await axios.post(`${env.apiURL}/api/leads`, req)).data;
      console.log({ res });

      let message = encodeURIComponent(`Hola! Muestro interés en el proyecto ${data.proyecto}`);

      if (res.group?.phone) {
        const url = `https://api.whatsapp.com/send?phone=${res.group?.phone}&text=${message}`;

        if (method === 'newTab') {
          window.open(url, '_blank').focus();
        }
        if (method === 'redirect') {
          window.location.replace(url);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  let nombresProyectos = ['Vizuale Eco Smart'];
  return (
    <Formik
      initialValues={{
        name: '',
        countryCode: form.defaultCountryCode,
        correo: '',
        phone: '',
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid }) => (
        <Form className="space-y-2">
          <FmInput
            name="name"
            type="text"
            label="Nombre y Apellido"
            placeholder="Escribe tu nombre"
          />

          <FmSelect name="countryCode" label="Código de país">
            {form.defaultCountryCode && getCountryByDialCode(form.defaultCountryCode) ? (
              <option value={`${form.defaultCountryCode}`}>
                {`${getCountryByDialCode(form.defaultCountryCode).name} (${
                  getCountryByDialCode(form.defaultCountryCode).dial_code
                })`}
              </option>
            ) : (
              <option value="">Selecciona una opción</option>
            )}

            {countryCodes.map((countryCode, index) => {
              return (
                <option key={index} value={`${countryCode.dial_code.replace(/\+/g, '')}`}>
                  {`${countryCode.name} (${countryCode.dial_code})`}
                </option>
              );
            })}
          </FmSelect>
          <FmInput name="phone" type="tel" label="Teléfono" placeholder="Escribe tu teléfono" />
          <FmInput name="email" type="email" label="Correo" placeholder="Escribe tu correo" />
          <FmSelect name="proyecto" label="Proyecto de interés">
            <option value="">Seleccione una opción</option>
            {nombresProyectos.map((name_, index) => {
              return (
                <option key={index} value={name_}>
                  {name_}
                </option>
              );
            })}
          </FmSelect>
          <Button type="submit" disabled={!isValid || loading}>
            {loading && (
              <div className="flex items-center justify-center">
                <div className="animate-spin">
                  <CgSpinner size="24px" />
                </div>
              </div>
            )}
            {!loading && 'Enviar'}
          </Button>

          <div className="text-center text-gray-500">
            <small>
              &copy; Powered by{' '}
              <a href="https://www.mantra.chat/" target="_blank" rel="noreferrer">
                Mantra.chat
              </a>
            </small>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FWOrtizInmobiliaria;
