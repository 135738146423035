import * as yup from 'yup';
import { Formik, Form } from 'formik';
import Button from '@components/Button';
import FmInput from '@components/formik/FmInput';
import FmSelect from '@components/formik/FmSelect';
import axios from 'axios';
import { useState } from 'react';
import { CgSpinner } from 'react-icons/cg';
import { useGetUtm } from '@/hooks/utmHooks';
// import getEnvironment from '@/env/getEnvironment';
import { countryCodes, getCountryByDialCode } from '@/data/countryCodes';

// const env = getEnvironment();

const formSchema = yup.object().shape({
  name: yup.string().required('Tu nombre es obligatorio'),
  phone: yup.string().required('Tu teléfono es obligatorio'),
  // email: yup.string().email('Correo electrónico no válido').required('Campo obligatorio'),
  opselect: yup.string().min(1, 'Campo obligatorio').required('Campo obligatorio'),
});

const FWMilepa = ({ form, method = 'newTab', source = 'widget-form' }) => {
  const [loading, setLoading] = useState(false);
  const utm = useGetUtm();

  const handleSubmit = async data => {
    setLoading(true);
    try {
      const req = {
        formId: form.id,
        countryCode: form.defaultCountryCode.replace(/\+/g, ''),
        phone: data.phone,
        name: data.name,
        // surname: '-',
        source,
        opselect: data.opselect,
        utm,
        ...data,
      };
      console.log({ req });
      // const res = (await axios.post(`${env.apiURL}/api/leads`, req)).data;
      const res = (await axios.post(`https://getform.io/f/5c8bf103-0fdc-4ef4-8f7b-256476e72695`, req)).data;
      console.log({ res });

      const linebreak = `%0A`;

      let message = encodeURIComponent(`Nombre y apellido: ${data.name}`);
      message += linebreak;
      // message += encodeURIComponent(`Correo electrónico: ${data.email}`);
      // message += linebreak;
      message += encodeURIComponent(`Área de interés,: ${data.opselect}`);
      message += linebreak;

      
        const url = `https://api.whatsapp.com/send?phone=59892776061&text=${message}`;

        if (method === 'newTab') {
          window.open(url, '_blank').focus();
        }
        if (method === 'redirect') {
          window.location.replace(url);
        }
      
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        name: '',
        phone: '',
        opselect: '',
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid }) => (
        <Form className="space-y-2">
          <FmInput
            name="name"
            type="text"
            label="Nombre"
            placeholder="Escribe tu nombre"
          />
        
         <FmSelect name="countryCode" label="Código de país">
            {form.defaultCountryCode && getCountryByDialCode(form.defaultCountryCode) ? (
              <option value={`${form.defaultCountryCode}`}>
                {`${getCountryByDialCode(form.defaultCountryCode).name} (${
                  getCountryByDialCode(form.defaultCountryCode).dial_code
                })`}
              </option>
            ) : (
              <option value="">Selecciona una opción</option>
            )}

            {countryCodes.map((countryCode, index) => {
              return (
                <option key={index} value={`${countryCode.dial_code.replace(/\+/g, '')}`}>
                  {`${countryCode.name} (${countryCode.dial_code})`}
                </option>
              );
            })}
          </FmSelect>
          
          <FmInput name="phone" type="tel" label="Teléfono (Sin codigo de país, solo número)" placeholder="099888222" />

          {/* <FmInput
            name="email"
            type="email"
            label="Correo electrónico"
            placeholder="Escribe tu correo electrónico"
          /> */}

          <FmSelect name="opselect" label="Área de interés">
            <option value="">Selecciona una opción</option>
            <option value="Asistencia al hogar y oficina">Asistencia al hogar y oficina</option>
            <option value="Purificadores de agua y aire">Purificadores de agua y aire</option>
            <option value="Piscinas">Piscinas</option>
            <option value="Jacuzzi">Jacuzzi</option>
            <option value="Artículos de mantenimiento">Artículos de mantenimiento</option>
            <option value="Otros">Otros</option>
          </FmSelect>

          <Button type="submit" disabled={!isValid || loading}>
            {loading && (
              <div className="flex items-center justify-center">
                <div className="animate-spin">
                  <CgSpinner size="24px" />
                </div>
              </div>
            )}
            {!loading && 'Enviar'}
          </Button>

          <div className="text-center text-gray-500">
            <small>
              &copy; Powered by{' '}
              <a href="https://www.mantra.chat/" target="_blank" rel="noreferrer">
                Mantra.chat
              </a>
            </small>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FWMilepa;
