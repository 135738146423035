import { useState } from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import Modal from 'react-modal';
import FmInput from '@components/formik/FmInput';
import FmSelect from '@components/formik/FmSelect';
import Button from '@components/Button';
import { CgSpinner } from 'react-icons/cg';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { countryCodes } from '@/data/countryCodes';
import { toast } from 'react-toastify';
import axios from '@/../node_modules/axios/index';
import { getAvailableFormTemplatesByGroupId } from '@/data/availableFormTemplates';
import getEnvironment from '@/env/getEnvironment';

const env = getEnvironment();

const formSchema = yup.object().shape({
  name: yup.string().required('El nombre de tu formulario es obligatorio'),
  defaultCountryCode: yup
    .string()
    .required('El código de país por defecto es obligatorio')
    .min(2, 'El código de país por defecto es obligatorio'),
  template: yup.string().required('Este campo es obligatorio'),
  webhookAddress: yup.string().url('URL no válida'),
});

const CreateForm = ({ token, groupId, onReload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async data => {
    console.log(data);

    setLoading(true);

    try {
      const createFormRes = await axios.post(`${env.apiURL}/api/forms/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          GroupId: groupId,
        },
      });

      console.log(createFormRes);

      toast.success('Formulario creado con éxito');
    } catch (error) {
      toast.error(error.message || 'Ocurrió un error inesperado');
      console.log(error);
    } finally {
      setLoading(false);
      onReload && onReload();
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        ariaHideApp={false}
        contentLabel="Crear formulario"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        style={{
          content: {
            position: 'absolute',
            border: '0px',
            background: '#F5F7FB',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: '20px',
            margin: '0 auto',
            borderRadius: '0px',
            inset: 0,
          },
        }}
      >
        <Formik
          initialValues={{
            name: '',
            defaultCountryCode: '',
            template: '',
            webhookAddress: '',
          }}
          validationSchema={formSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid }) => (
            <Form className="space-y-2 text-[#495057]">
              <div
                className="flex justify-start items-center space-x-2 cursor-pointer"
                onClick={() => {
                  if (loading) return;
                  setIsModalOpen(false);
                }}
              >
                <div>
                  <AiOutlineArrowLeft size="25px" />
                </div>
                <span className="text-lg">Regresar</span>
              </div>

              <h1 className="font-bold text-lg md:text-2xl mt-2">Crear formulario nuevo</h1>

              <FmInput
                name="name"
                type="text"
                label="Nombre"
                placeholder="Nombre de tu formulario"
              />

              <div>
                <FmSelect name="defaultCountryCode" label="Código de país por defecto">
                  <option value="">Selecciona una opción</option>
                  {countryCodes.map((countryCode, index) => {
                    return (
                      <option key={index} value={`${countryCode.dial_code.replace(/\+/g, '')}`}>
                        {`${countryCode.name} (${countryCode.dial_code})`}
                      </option>
                    );
                  })}
                </FmSelect>
                <small>
                  Este es el código de país por defecto para las personas que llenen el formulario.
                </small>
              </div>

              <div>
                <FmSelect name="template" label="Plantilla del formulario">
                  <option value="">Selecciona una opción</option>
                  {getAvailableFormTemplatesByGroupId(groupId).map((template, index) => {
                    return (
                      <option key={index} value={template.id}>
                        {template.name}
                      </option>
                    );
                  })}
                </FmSelect>
              </div>

              <div>
                <FmInput
                  name="webhookAddress"
                  type="text"
                  label="Webhook (opcional)"
                  placeholder="URL de tu webhook"
                />
                <small>
                  Si deseas que tu formulario envíe datos a tu webhook, ingresa la URL aquí. Se
                  utilizará el método POST y se enviaran todos los campos del formulario en formato
                  JSON.
                </small>
              </div>

              <Button type="submit" disabled={!isValid || loading} variant="purple">
                {loading && (
                  <div className="flex items-center justify-center">
                    <div className="animate-spin">
                      <CgSpinner size="24px" />
                    </div>
                  </div>
                )}
                {!loading && 'Crear'}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>

      <Button onClick={() => setIsModalOpen(true)} variant="purple">
        Crear formulario
      </Button>
    </div>
  );
};

export default CreateForm;
