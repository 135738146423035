import * as qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import LoadingSpinner from '@/components/LoadingSpinner';
import CreateForm from '@/components/manageForms/CreateForm';
import ListForms from '@/components/manageForms/ListForms';
import getEnvironment from '@/env/getEnvironment';

const env = getEnvironment();

const ManageForms = () => {
  const [groupId, setGroupId] = useState('');
  const [token, setToken] = useState('');
  const [forms, setForms] = useState([]);
  const [loadingForms, setLoadingForms] = useState(true);

  // Obtener query parameters
  useEffect(() => {
    const query = qs.parse(window.location.search) || {};

    if (query.token && query.groupId) {
      setGroupId(query.groupId);
      setToken(query.token);
    }
  }, []);
  // Obtener query parameters

  // Obtener formularios del grupo
  const getGroupForms = useCallback(async () => {
    if (!groupId || !token) return;
    setLoadingForms(true);

    try {
      const formsRes = await axios.get(`${env.apiURL}/api/forms/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          GroupId: groupId,
        },
      });

      setForms(formsRes.data?.forms || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingForms(false);
    }
  }, [groupId, token]);

  useEffect(() => {
    getGroupForms();
  }, [getGroupForms]);
  // Obtener formularios del grupo

  if (!loadingForms && (!groupId || !token)) {
    return <div>Debes enviar los parametros token y groupId por query params</div>;
  }

  if (loadingForms) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="p-4 space-y-2 mx-auto bg-[#F5F7FB] min-h-screen text-[#495057]">
      <div>
        <h1 className="text-[21px] font-semibold">Mantra Forms</h1>
        <p>Crea formularios, comparte su link e insertalos en tu página web.</p>
      </div>

      {forms && forms.length < 1 && (
        <div className="flex flex-col items-center justify-center space-y-2 py-10">
          <lottie-player
            src="https://assets7.lottiefiles.com/packages/lf20_xvDzqH.json"
            background="transparent"
            speed="1"
            style={{ width: '300px', height: '150px' }}
            loop
            autoplay
          ></lottie-player>
          <h2 className="font-bold text-lg text-center">
            No se encontraron formularios creados para este grupo
          </h2>
        </div>
      )}

      <CreateForm
        token={token}
        groupId={groupId}
        onReload={() => {
          getGroupForms();
        }}
      />

      {forms && forms.length > 0 && (
        <ListForms
          forms={forms}
          token={token}
          groupId={groupId}
          onReload={() => {
            getGroupForms();
          }}
        />
      )}
    </div>
  );
};

export default ManageForms;
